<template>
  <div>
    <select-shop :loadingButton="loadingButton" v-on:getData="getData"></select-shop>

    <div v-if="isPermission">
      <CCard class="shadow-sm">
        <CCardBody>
          <div class="row">
            <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
            <h2 class="mb-0 font-weight-normal">
            <span id="salesChannelsTitle" >{{ $t('report.txtSalesChannelsReport') }}</span>
            </h2>
            <HelpButton :isHeader="false" class="ml-2"></HelpButton>
            </div>
            <div
              v-if="isExport"
              class="col-md-2 col-sm-3 text-right text-success"
            >
              <CButton id="salesChannelsExportButton" v-on:click="exportSalesChannelsReport()" block color="info">
                <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
              </CButton>
            </div>
          </div>
          <CRow>
            <CCol sm="12" lg="12">
              <show-date></show-date>
              <sortdata
                v-on:getSort="setSort"
                :sort_value="sort_value"
                :sortdata="sortdata"
              />
              <hr />

              <!-- CoreUI Line Chart Component -->
              <CChartLine
                :datasets="chartData.datasets"
                :options="chartOptions"
                :labels="chartData.labels"
                style="padding: 20px;height: 300px; position: relative;"
                v-if="showChartLine"
              />

              <!-- DataTable displaying data from items() method -->
              <DataTable
                id="salesChannelsDatatable"
                style="white-space: nowrap"
                :items="items"
                :fields="fields"
                hover
                border
                :loading="loading"
              >
                <template #salesChannels="{ item }">
                  <td class="text-left text-dark ">
                    <img :src="getSalesChannelImage(item.salesChannels)" alt="Icon" width="18" height="18" class="mr-1 align-middle" />
                    {{ item.salesChannels }}
                  </td>
                </template>
                <template #beforeGP="{ item }">
                  <td class="text-right text-dark">{{ item.beforeGP }}</td>
                </template>
                <template #afterGP="{ item }">
                  <td class="text-right text-dark">{{ item.afterGP }}</td>
                </template>
                <template #totalAmount="{ item }">
                  <td class="text-right text-dark">{{ item.totalAmount }}</td>
                </template>
              </DataTable>
            </CCol>
          </CRow>
          <pagination
            :meta_data="meta_data"
            v-on:next="getSalesChannelsReport"
          ></pagination>
        </CCardBody>
      </CCard>
    </div>
    <div v-else>
      <access-data></access-data>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import axios from "@/services/service";
import report from "@/services/report";
import Pagination from "@/containers/Pagination";
import Sortdata from "@/containers/SortData";
import DataTable from "@/containers/DataTable"; // Your original DataTable
import { CChartLine } from "@coreui/vue-chartjs";
import permis from "@/util/permission";
import util from "@/util/util";
import HelpButton from "../../containers/HelpButton.vue";

export default {
  components: {
    Pagination,
    Sortdata,
    DataTable,
    CChartLine,
    HelpButton
  },
  data() {
    return {
      showChartLine: false,
      dataDate: [],
      data: [],
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
      loadingButton: true,
      sortOrder: "asc",
      sortdata: "sortBySalesMin",
      sortnum: false,
      sort_value: {
        sortBySalesMin: "sortBySalesMin",
        sortBySalesMax: "sortBySalesMax",
        sortByBillAmountMin: "sortByBillAmountMin",
        sortByBillAmountMax: "sortByBillAmountMax",
      },
      chartData: {
        labels: [], // X-axis labels (dates)
        datasets: [], // Data for each line
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: false,
            ticks: {
              stepSize: 1000, 
              callback: function (value) {
                return value.toLocaleString();
              },
            },
            suggestedMin: 0,
            suggestedMax: 1500,
          },
        },
      },

    };
  },
  computed: {
    ...mapGetters(["shops", "date", "users"]),
    isPermission() {
      return permis.findPermissionRead("report", this.$route.path);
    },
    isExport() {
      return permis.findPermissionExport("report", this.$route.path);
    },
    sortAttr: {
      get() {
        let sortdata = this.sortdata;
        if (sortdata === "sortBySalesMin" || sortdata === "sortBySalesMax") {
          sortdata = "gpGrandTotal";
        }
        if (sortdata === "sortByBillAmountMin" || sortdata === "sortByBillAmountMax") {
          sortdata = "bill";
        }
        return sortdata;
      },
      set(newValue) {
        return newValue;
      },
    },
    uid() {
      let shop = this.shops.find((i) => i.objectId === this.shopObjectId);
      return shop.uid;
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    startDate() {
      return moment(String(this.date.start)).format("DD MMMM YYYY");
    },
    endDate() {
      return moment(String(this.date.end)).format("DD MMMM YYYY");
    },
    fields() {
      return [
        {
          key: "id",
          label: this.$i18n.t("#"),
          _classes: "text-center text-dark font-weight-normal",
          _style: "width:5%",
        },
        {
          key: "salesChannels",
          label: this.$i18n.t("txtSalesChannels"),
          _classes: "text-left text-dark font-weight-normal ",
          _style: "width:25%",
        },
        {
          key: "bill",
          label: this.$i18n.t("billAmount"),
          _classes: "text-right text-dark font-weight-normal",
          _style: "width:10%",
        },
        {
          key: "beforeGP",
          label: this.$i18n.t("beforeGP"),
          _classes: "text-right text-dark font-weight-normal",
          _style: "width:15%",
        },
        {
          key: "afterGP",
          label: this.$i18n.t("afterGP"),
          _classes: "text-right text-dark font-weight-normal",
          _style: "width:15%",
        },
        {
          key: "totalAmount",
          label: this.$i18n.t("grandTotal"),
          _classes: "text-right text-dark font-weight-normal",
          _style: "width:15%",
        },
      ];
    },
    items() {
      let data = this.data || [];
      let detail = [];
      for (let i = 0; i < data.length; i++) {
        const item = data[i];
        let salesChannels = "";
        if (item.name === "ราคาขายปกติหน้าร้าน") {
          salesChannels = this.$t("txtStore");
        } else {
          salesChannels = item.name;
        }
        // Parse and format beforeGP and afterGP to two decimal places
        let beforeGP = parseFloat(item.beforeGP).toFixed(2);
        let afterGP = parseFloat(item.gpAmount).toFixed(2);
     
        // Calculate totalAmount as beforeGP - afterGP
        let totalAmount = (item.beforeGP - item.gpAmount).toFixed(2);


        detail.push({
          id: i + 1,
          salesChannels: salesChannels,
          bill: item.bill,
          beforeGP: util.convertCurrency(Number(beforeGP)), 
          afterGP: util.convertCurrency(Number(afterGP)), 
          totalAmount: util.convertCurrency(Number(totalAmount)), 
          grandTotal: util.convertCurrency(item.grandTotal),
        });
      }
      return detail;
    },
  },
  created() {
    this.getSalesChannelsReport();
    this.getSalesChannelsSum();
  },
  mounted() {
    this.getSalesChannelsSum();
  },
  methods: {
    getSalesChannelImage(salesChannel) {
      if (salesChannel === 'Grab') {
        return '/img/delivery/grab.png'
      } else if (salesChannel === 'Kiosk') {
        return '/img/delivery/pru_store.png'
      } else if (salesChannel === 'QRorder') {
        return '/img/delivery/pru_store.png'
      } else if (salesChannel === 'Lineman') {
        return '/img/delivery/LINEMAN.png'
      } else if (salesChannel === 'Foodpanda') {
        return '/img/delivery/foodpanda.png'
      } else if (salesChannel === 'Shopeefood') {
        return '/img/delivery/Shopee-food.png'
      } else if (salesChannel === 'Truefood') {
        return '/img/delivery/truefood.png'
      } else if (salesChannel === 'Robinhood') {
        return '/img/delivery/Robinhood.png'
      } else if (salesChannel === '201:SilomPOS Online') {
        return '/img/delivery/pru_store.png'
      } else {
        return '/img/delivery/pru_store.png'
      }
    },
    getData() {
      this.getSalesChannelsReport();
      this.getSalesChannelsSum();
    },
    setSort(value) {
      this.sortdata = value;
      this.getSalesChannelsReport();
    },
    getSalesChannelsReport(page = 1) {
      this.loadingButton = false;
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
      const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");
      let sortOrder = "";
      let sort = "";
      if (this.sortdata === "name") {
        sort = "name";
      } else if (
        this.sortdata === "sortBySalesMin" ||
        this.sortdata === "sortBySalesMax"
      ) {
        sort = "gpGrandTotal";
      } else if (
        this.sortdata === "sortByBillAmountMin" ||
        this.sortdata === "sortByBillAmountMax"
      ) {
        sort = "bill";
      } else {
        sort = this.sortdata;
      }
      if (this.sortdata === "sortBySalesMin" || this.sortdata === "sortByBillAmountMin") {
        sortOrder = "asc";
      } else if (
        this.sortdata === "sortBySalesMax" ||
        this.sortdata === "sortByBillAmountMax"
      ) {
        sortOrder = "desc";
      } else {
        sortOrder = "asc";
      }
      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
        sortattr: sort,
        sortorder: sortOrder,
      };
      const headers = {
        shopObjectId: shopObjectId,
      };
      this.loading = true;
      axios({
        url: "/receipt/v1.0/sellchannelsum/" + uid + "/data",
        params: params,
        headers: headers,
        method: "GET",
      })
        .then((res) => {
          this.data = res.data.data;
          if (res.data.paginate.items == undefined) {
            this.meta_data.items = 0;
          } else {
            this.meta_data.items = res.data.paginate.items;
          }
          this.meta_data.last_page = res.data.paginate.pageCount;
          this.meta_data.current_page = res.data.paginate.currentPage;
          this.meta_data.itemCount = res.data.paginate.itemCount;
          this.meta_data.limit = res.data.paginate.perPage;
          this.loading = false;
          this.loadingButton = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSalesChannelsSum(page = 1) {
      this.loadingButton = false;
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;
      const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
      const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      };
      const headers = {
        shopObjectId: shopObjectId,
      };

      this.loading = true;
      axios({
        url: "/receipt/v1.0/sellchannelsum/" + uid + "/date",
        params: params,
        headers: headers,
        method: "GET",
      })
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            this.showChartLine = true
            this.dataDate = res.data.data
            this.prepareChartData()
          } else {
            this.showChartLine = false
          }
          this.loadingButton = true
        })
        .catch((error) => {
          console.log(error)
          this.showChartLine = false
          this.loadingButton = true
        });
    },
    prepareChartData() {
      let data = this.dataDate;
      console.log(data);
      
      const labels = [
        ...new Set(data.map((item) => moment(item.date).format("YYYY-MM-DD"))),
      ];
      if (labels.length == 1) {
        let firstDate = moment(labels[0], "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD");
        labels.unshift(firstDate)
      }

      const groupedData = {};
      data.forEach((item) => {
        if (!groupedData[item.name]) {
          groupedData[item.name] = Array(labels.length).fill(0)
        }
        const dateIndex = labels.indexOf(moment(item.date).format("YYYY-MM-DD"));
        if (dateIndex !== -1) {
          groupedData[item.name][dateIndex] = item.beforeGP - item.gpAmount
        }
      });

      const datasets = Object.keys(groupedData).map((name, index) => ({
        label: name, 
        data: groupedData[name], 
        borderColor: this.getLineColor(index),
        fill: false,
        tension: 0.1,
      }));

      this.chartData = {
        labels: labels, 
        datasets: datasets, 
      };
    },

    getLineColor(index) {
      const colors = [
        "#AEC6CF", // Pastel Blue
        "#FFB347", // Pastel Orange
        "#77DD77", // Pastel Green
        "#FF6961", // Pastel Red
        "#F49AC2", // Pastel Pink
        "#CFCFC4", // Pastel Gray
        "#FDFD96", // Pastel Yellow
        "#84B6F4", // Pastel Light Blue
        "#C4A7E7", // Pastel Purple
        "#FFD1DC", // Pastel Light Pink
        "#B39EB5", // Pastel Mauve
        "#FFDAC1", // Pastel Peach
        "#B0E57C", // Pastel Lime Green
        "#E0BBE4", // Pastel Lavender
      ];
      return colors[index % colors.length];
    },
    exportSalesChannelsReport() {
      const uid = `${localStorage.getItem("shopsUid")}`;
      const shopObjectId = this.shopObjectId;

      const startAt = moment(String(this.date.start)).format("YYYY-MM-DD");
      const endAt = moment(String(this.date.end)).format("YYYY-MM-DD");

      const params = {
        shopObjectId: shopObjectId,
        startAt: startAt,
        endAt: endAt,
      };
      const headers = {
        shopObjectId: shopObjectId,
      };

      report({
        url: "/receipt/v1.0/sellchannelsum/" + uid + "/exel",
        params: params,
        method: "GET",
        headers: headers,
        responseType: "blob", // important
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "รายงานยอดขายตามช่องทางการขาย.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.chart-container {
  height: 400px;
  position: relative;
}
</style>
